.App {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  background-color: #121212;
  margin: 0;
  padding: 0;
}

p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a:hover {
  text-decoration: none;
}

button:active, button:focus {
  outline: none;
}

::-moz-selection {
  color: #2196f3;
  background-color: black;
}

::selection {
  color: #2196f3;
  background-color: black;
}

/* custom text css */

.emphasis-heading {
  font-family: 'Inter', sans-serif;
}

.blue-gradient-text {
  background: -webkit-linear-gradient(
    left,
    #2196f3,
    #26c6da,
    #43a047
  );
  background: linear-gradient(
    to right,
    #2196f3,
    #26c6da,
    #43a047
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* loader css */

.spinner {
  margin: 100px auto;
  width: 50px;
  height: 60px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background: #2196f3;
  height: 100%;
  width: 10px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

/* owl carousel customisation */

.navitem {
  position: absolute;
  background-color: rgba(0,0,0,0.925);
  width: 75%;
}

.owl-prev, .owl-next {
  position: absolute;
  top: 40vm;
  top: 40vmin;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 100%;
  width: 100px;
  border-radius: 2rem;
  /* background-color: rgba(0, 0, 0, 0.24)!important; */
}
.owl-prev { left: -10vw; }
.owl-next { right: -10vw; }

.owl-prev:hover, .owl-next:hover {
  background-color: transparent!important;
  color: #6EE6B6!important;
}

.owl-image {
  height: 75%;
  width: 75%;
}

.name {
  background:
  -webkit-linear-gradient(left, #6EE6B6, #6EE6B6) 50% 100% /var(--d, 0) 2px no-repeat,
  -webkit-linear-gradient(left, white, white) 0% 100% /var(--p, 0) 2px no-repeat,
  -webkit-linear-gradient(left, white, white) 100% 100% /var(--p, 0) 2px no-repeat;
  background:
  linear-gradient(to right, #6EE6B6, #6EE6B6) 50% 100% /var(--d, 0) 2px no-repeat,
  linear-gradient(to right, white, white) 0% 100% /var(--p, 0) 2px no-repeat,
  linear-gradient(to right, white, white) 100% 100% /var(--p, 0) 2px no-repeat;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  --d:50%;
  --p:24%;
}

.name:hover {
  --d: 100%;
  --p:0%;
}

.green-underline {
  background:
  -webkit-linear-gradient(left, #6EE6B6, #6EE6B6) 0% 100% /var(--d, 0) 2px no-repeat,
  -webkit-linear-gradient(left, white, white) 100% 100% /var(--p, 0) 2px no-repeat;
  background:
  linear-gradient(to right, #6EE6B6, #6EE6B6) 0% 100% /var(--d, 0) 2px no-repeat,
  linear-gradient(to right, white, white) 100% 100% /var(--p, 0) 2px no-repeat;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  --d:75%;
  --p:24%;
}

.green-underline:hover {
  --d: 100%;
  --p:0%;
}

.white-underline {
  background:
  -webkit-linear-gradient(left, white, white) 0% 100% /var(--d, 0) 2px no-repeat,
  -webkit-linear-gradient(left, #6EE6B6, #6EE6B6) 100% 100% /var(--p, 0) 2px no-repeat;
  background:
  linear-gradient(to right, white, white) 0% 100% /var(--d, 0) 2px no-repeat,
  linear-gradient(to right, #6EE6B6, #6EE6B6) 100% 100% /var(--p, 0) 2px no-repeat;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  --d:75%;
  --p:24%;
}

.white-underline:hover {
  --d: 100%;
  --p:0%;
}

/* view more button styles */

.view-more { 
  background-color: transparent;
  color: white;
}

.view-more:hover {
  background-color: rgb(233, 233, 233);
  color: black;
  border: none;
}

/* scrollbar styles */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #121212;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6ee7b7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fefefe;
}

/* footer styles */

footer a {
  color: #6ee7b7;
}

footer a:hover {
  color: white;
  text-decoration: none;
}

.social-links a {
  color: white;
}

.social-links a:hover {
  color: #26c6da;
}

/* mobile designs */

@media only screen and (max-width: 800px) {
  .navitem {
    position: relative;
    width: 100%;
    background-color: transparent;
  }
  .owl-image {
    width: 100%;
    height: 100%;
  }
  .owl-nav {
    display: none;
  }
}